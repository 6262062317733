
import {
  computed, defineComponent, PropType, watch, ref,
} from 'vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import { debounce } from 'vue-debounce';
import { useRouter } from 'vue-router/composables';
import BaseCarousel from '@/components/ui/BaseCarousel/BaseCarousel.vue';
import { UUID } from '@/domains/common';
import CatalogueAtomCard, { CatalogueAtomCardSkeleton } from '@/components/catalogue/CatalogueAtomCard';
import { AtomType, ICatalogueAtomFilters } from '@/domains/atom';
import { useCatalogueAtomsPageable } from '@/domains/atom/composables/useCatalogueAtomsPageable';
import { useDi } from '@/plugins';
import { Names } from '@/plugins/vue-router';
import * as CatalogueService from '@/services/api/catalogue';
import CatalogueExtendedAtomCard from '@/components/catalogue/CatalogueAtomCard/CatalogueExtendedAtomCard.vue';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';

export default defineComponent({
  name: 'CatalogueAtomsSection',
  components: {
    BaseCarousel,
    CatalogueAtomCard,
    TTBtn,
    CatalogueAtomCardSkeleton,
    CatalogueExtendedAtomCard,
  },
  inject: ['Names'],
  props: {

    value: {
      type: Boolean,
      required: false,
      default: false,
    },

    atomTypes: {
      type: Array as PropType<AtomType[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    filters: {
      type: Object as PropType<ICatalogueAtomFilters>,
      default: () => ({} as ICatalogueAtomFilters),
    },
    extendedCards: {
      type: Boolean,
      default: false,
    },

    isRecommendations: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isSectionEmpty = computed({
      get: () => props.value,
      set: (newValue) => emit('input', newValue),
    });

    const currentSlide = ref(0);

    const itemsPerSlide = 4;
    const { di } = useDi();
    const router = useRouter();
    const { getAtomPreviewUrl } = useCatalogueAtomCard();
    const {
      items,
      isLoading,
      total,
      fetchNextPage,
      fetchAtoms,
      limit,
      error,
    } = useCatalogueAtomsPageable({ fetchAtomsMethod: CatalogueService.userTracksGet });
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: false });

    const sectionFilters = computed<ICatalogueAtomFilters>(() => ({
      ...props.filters,
      atomNames: props.atomTypes,
    }));

    const onFiltersChanged = debounce(async () => {
      await fetchAtoms(sectionFilters.value);
      currentSlide.value = 0;
    }, 250);

    const onNextPageClicked = async () => {
      await fetchNextPage(sectionFilters.value);
    };

    const goToTrack = ({ id }: { id: UUID }) => router.push({
      name: Names.R_APP_LEARNING_ATOM_PREVIEW,
      params: {
        catalogueAtomId: id,
      },
    });

    const isAtomsCarouselVisible = computed(() => !isLoading.value && !error.value);
    const isSkeletonsVisible = computed(() => isLoading.value || error.value || !items.value.length);
    const onShowAllButtonClicked = () => {
      emit('show-all');
    };

    watch(sectionFilters, () => {
      onFiltersChanged();
    }, { immediate: true });

    watch(error, (errorCode) => {
      if (errorCode) {
        di.notify.error({ content: 'Не удалось получить атомы' });
      }
    });

    watch(items, () => {
      if (items.value.length === 0) {
        isSectionEmpty.value = true;
      } else {
        isSectionEmpty.value = false;
      }
    });

    return {
      items,
      isLoading,
      total,
      limit,
      onNextPageClicked,
      itemsPerSlide,
      onAssignAtom,
      goToTrack,
      error,
      isAtomsCarouselVisible,
      isSkeletonsVisible,
      getAtomPreviewUrl,
      onShowAllButtonClicked,
      currentSlide,
    };
  },
});
