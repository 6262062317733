
import {
  computed,
  defineComponent, onMounted, PropType, watch,
} from 'vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import BaseCarousel from '@/components/ui/BaseCarousel/BaseCarousel.vue';
import { CatalogueAtomCardSkeleton } from '@/components/catalogue/CatalogueAtomCard';
import { ICatalogueAtomFilters } from '@/domains/atom';
import { useCatalogueAtomsPageable } from '@/domains/atom/composables/useCatalogueAtomsPageable';
import { useDi } from '@/plugins';
import CatalogueExtendedAtomCard from '@/components/catalogue/CatalogueAtomCard/CatalogueExtendedAtomCard.vue';
import * as CatalogueService from '@/services/api/catalogue';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';

export default defineComponent({
  name: 'CatalogueRecommendationsSection',
  components: {
    BaseCarousel,
    TTBtn,
    CatalogueAtomCardSkeleton,
    CatalogueExtendedAtomCard,
  },
  inject: ['Names'],
  props: {
    title: {
      type: String,
      required: true,
    },
    filters: {
      type: Object as PropType<ICatalogueAtomFilters>,
      default: () => ({} as ICatalogueAtomFilters),
    },
    isBigCards: {
      type: Boolean,
      default: false,
    },

    isRecommendations: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const itemsPerSlide = 4;
    const { di } = useDi();
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: true });
    const { getAtomPreviewUrl } = useCatalogueAtomCard();
    const {
      items,
      isLoading,
      total,
      fetchNextPage,
      fetchAtomsWithLoadingFlag,
      limit,
      error,
    } = useCatalogueAtomsPageable({ fetchAtomsMethod: CatalogueService.recommendedTracksGet });

    const sectionFilters = computed(() => ({
      ...props.filters,
    }));

    const onNextPageClicked = async () => {
      await fetchNextPage(sectionFilters.value);
    };

    const isAtomsCarouselVisible = computed(() => !isLoading.value && !error.value);
    const isSkeletonsVisible = computed(() => isLoading.value || error.value || !items.value.length);
    const onShowAllButtonClicked = () => {
      emit('show-all');
    };

    onMounted(async () => {
      await fetchAtomsWithLoadingFlag(sectionFilters.value);
    });

    watch(error, (errorCode) => {
      if (errorCode) {
        di.notify.error({ content: 'Не удалось получить атомы' });
      }
    });

    return {
      items,
      isLoading,
      total,
      limit,
      onNextPageClicked,
      itemsPerSlide,
      onAssignAtom,
      getAtomPreviewUrl,
      error,
      isAtomsCarouselVisible,
      isSkeletonsVisible,
      onShowAllButtonClicked,
    };
  },
});
