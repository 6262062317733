<template>
  <article class="base-carousel">
    <div
      v-if="slides.length > 1"
      class="base-carousel__actions"
    >
      <TTBtn
        x-small
        rounded
        color="tt-light-mono-8"
        height="28"
        :disabled="slides.length && currentSlide === 0"
        @click="prev"
      >
        <VIcon>
          $angleLeft
        </VIcon>
      </TTBtn>
      <TTBtn
        class="ml-3"
        x-small
        rounded
        color="tt-light-mono-8"
        height="28"
        :disabled="slides.length && currentSlide === slides.length - 1"
        @click="next"
      >
        <VIcon>
          $angleRight
        </VIcon>
      </TTBtn>
    </div>
    <VCarousel
      ref="carousel"
      v-model="currentSlide"
      height="max-content"
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
      light
    >
      <VCarouselItem
        v-for="(slide, slideIdx) in slides"
        :key="`carousel-slide-id-${slideIdx}`"
      >
        <VRow class="my-0">
          <VCol
            v-for="(item, itemIdx) in slide"
            :key="`carousel-slide-item-id-${itemIdx}`"
            :cols="itemCols"
            class="py-0"
          >
            <slot
              name="item"
              :item="item"
            />
          </VCol>
        </VRow>
      </VCarouselItem>
    </VCarousel>
  </article>
</template>

<script>
import Vue from 'vue';
import { chunk } from '@/helpers';

export default Vue.extend({
  name: 'BaseCarousel',
  props: {
    value: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsInSlide: {
      type: Number,
      default: 3,
    },
    itemCols: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    slides() {
      return chunk(this.items, this.itemsInSlide);
    },
  },
  watch: {
    currentSlide: {
      handler(v) {
        if (v === this.slides.length - 1) {
          this.$emit('click:next');
        }

        if (this.value !== null) {
          this.currentSlide = this.value;
        }
      },
    },

    value: {
      immediate: true,
      handler(v) {
        this.currentSlide = v ?? 0;
      },
    },
  },
  methods: {
    next() {
      this.$refs.carousel.next();
      this.$emit('input', this.value + 1);
    },
    prev() {
      this.$refs.carousel.prev();
      this.$emit('click:prev');
      this.$emit('input', this.value - 1);
    },
  },
});
</script>

<style lang="scss">
.base-carousel {
  position: relative;

  &__actions {
    position: absolute;
    right: 0;
    top: -44px;
  }

  /* Чтобы не обрезалась тень у карточек внутри слайда */
  .v-window,
  .v-carousel__item {
    overflow: visible;
  }
}
</style>
