
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';
import { Names } from '@/plugins/vue-router';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import InputSearch from '@/components/ui/InputSearch.vue';
import CatalogueAtomsSection from '@/components/catalogue/CatalogueAtomsSection';
import { AtomSort, AtomType, ICatalogueAtomFilters } from '@/domains/atom';
import { UUID } from '@/domains/common';
import CatalogueRecommendationsSection from '@/components/catalogue/CatalogueAtomsSection/CatalogueRecommendationsSection.vue';
import EmptyAtomsFilteredList from '@/components/catalogue/EmptyAtomsFilteredList.vue';

export default defineComponent({

  name: 'LearningCatalogue',

  components: {
    TTBackButton,
    UpButton,
    InputSearch,
    CatalogueAtomsSection,
    CatalogueRecommendationsSection,
    EmptyAtomsFilteredList,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isMicroEducationSectionEmpty = ref(false);
    const isTracksSectionEmpty = ref(false);
    const isTestsSectionEmpty = ref(false);

    const catalogueFilters = reactive<ICatalogueAtomFilters>({
      atomNames: [] as AtomType[],
      search: '',
      sort: AtomSort.UPDATED_AT_DESC,
      providers: [] as UUID[],
      categories: [] as UUID[],
      durations: [] as [Number | null, Number | null][],
    });

    const breadcrumbs = computed(() => ([
      {
        text: t('LearningCatalogue.breadcrumbs.programs'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },

      {
        text: t('LearningCatalogue.breadcrumbs.allPrograms'),
        disabled: true,
        'data-test-label': 'catalogue-link',
      },
    ]));

    const onShowAllMulticontents = () => {
      router.push({
        name: Names.R_APP_LEARNING_CATALOGUE_LESSONS,
      });
    };

    const onShowAllTests = () => {
      router.push({
        name: Names.R_APP_LEARNING_CATALOGUE_TESTS,
      });
    };

    const onShowAllRecommended = () => {
      router.push({
        name: Names.R_APP_LEARNING_CATALOGUE_RECOMMENDATIONS,
      });
    };

    const onShowAllCourses = () => {
      router.push({
        name: Names.R_APP_LEARNING_CATALOGUE_COURSES,
      });
    };

    const isAllSectionsEmpty = computed(() =>
      isMicroEducationSectionEmpty.value
      && isTracksSectionEmpty.value
      && isTestsSectionEmpty.value);

    return {
      catalogueFilters,
      breadcrumbs,
      AtomType,
      onShowAllCourses,
      onShowAllMulticontents,
      onShowAllTests,
      onShowAllRecommended,
      isMicroEducationSectionEmpty,
      isTracksSectionEmpty,
      isTestsSectionEmpty,
      isAllSectionsEmpty,
    };
  },
});
