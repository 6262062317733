
import Vue, { PropType } from 'vue';
import { AtomType } from '@/domains/atom';

type IconSettings = {
  name: string,
  color: {
    background: string,
    icon: string,
  },
  hint: string,
}

export default Vue.extend({
  name: 'AtomTypeIcon',
  props: {
    value: {
      type: String as PropType<AtomType>,
      required: true,
    },
    dataTest: {
      type: String,
      default: 'atom-type',
    },
  },
  computed: {
    icon(): IconSettings {
      const resolver: Record<AtomType, IconSettings> = {
        [AtomType.MULTICONTENT]: {
          name: '$play',
          color: { background: 'tt-color-cyan-50', icon: 'tt-color-cyan-200' },
          hint: this.$t('AtomTypeIcon.hint.lesson'),
        },
        [AtomType.MULTILEVELTEST]: {
          name: '$clipboardCheck',
          color: { background: 'tt-light-purple pale', icon: 'tt-light-purple base' },
          hint: this.$t('AtomTypeIcon.hint.test'),
        },
        [AtomType.QUIZ]: {
          name: '$questionSquare',
          color: { background: 'tt-light-yellow pale', icon: 'tt-light-yellow base' },
          hint: this.$t('AtomTypeIcon.hint.quiz'),
        },
        [AtomType.SCORM]: {
          name: '$scorm',
          color: { background: 'tt-color-pink-50 base', icon: 'tt-color-pink-400 base' },
          hint: this.$t('AtomTypeIcon.hint.scorm'),
        },
        [AtomType.TRACK]: {
          name: '',
          color: { background: '', icon: '' },
          hint: '',
        },
        [AtomType.PROGRAM]: {
          name: '$list',
          color: { background: 'tt-color-green-50', icon: 'tt-color-green-300' },
          hint: '',
        },
        [AtomType.UNKNOWN]: {
          name: '',
          color: { background: '', icon: '' },
          hint: '',
        },
      };

      return resolver[this.value];
    },
  },
});
