
import {
  computed,
  defineComponent, PropType,
} from 'vue';
import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import { RawLocation } from 'vue-router';
import { UUID } from '@/domains/common';
import AtomTypeIcon from './AtomTypeIcon.vue';
import { AtomType } from '@/domains/atom';
import PlusButton from '@/components/tracks/PlusButton';
import { ButtonState } from '@/components/tracks/PlusButton/types';

export default defineComponent({
  name: 'CatalogueAtomCard',

  components: {
    TTPrompt,
    AtomTypeIcon,
    PlusButton,
  },

  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      required: true,
    },

    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },

    atomTitle: {
      type: String,
      required: true,
    },

    atomId: {
      type: String as PropType<UUID>,
      required: true,
    },

    coverImageId: {
      type: String as PropType<UUID>,
      default: '',
    },

    isAssigned: {
      type: Boolean,
      default: false,
    },
    assignButton: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const assignButtonInitialState = computed(() => (props.isAssigned ? ButtonState.CHECKED : ButtonState.DEFAULT));
    const onAssignButtonClickedHandler = (resolvePromise: Function) => {
      emit('click:assign', { id: props.atomId, resolvePromise });
    };

    return {
      assignButtonInitialState,
      onAssignButtonClickedHandler,
    };
  },
});
